<template>
	<div class="container">
		<div class="title-wrap">
			<h2 class="title">추천 상태 조회</h2>
		</div>
			<el-input placeholder="요청 ID" v-model="messageId" clearable> </el-input>
       <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="key"
        label="항목">
      </el-table-column>
      <el-table-column
        prop="value"
        label="값">
      </el-table-column>
    </el-table>
    <br/>
    <div style="float: right; display: inline-block;">
      <el-button type="success" :disabled="!response_load" @click="loadVoteStatus">{{response_button_text}}</el-button>
    </div> 
    
	</div>
</template>

<script>
import axios from "axios";
import {apiUrl} from '../constants/config';
import { Loading } from 'element-ui';

var loadingInstance;

export default {
	data() {
		return {
      response_load: true,
      response_data: null,
      response_button_text: "조회",
      messageId: "",
      tableData: []
		}
	},
	methods: {
    makeTable(){
      this.tableData = [];
      if(this.response_data.status == 1){
        this.tableData.push({
          key: "상태",
          value: "처리됨"
        })
        this.tableData.push({
          key: "추천 ID",
          value: this.response_data.voteIdx
        })
        this.tableData.push({
          key: "추천 서버",
          value: this.response_data.server
        })
        this.tableData.push({
          key: "마인크래프트 닉네임",
          value: this.response_data.mc_nick
        })
        this.tableData.push({
          key: "IP",
          value: this.response_data.ip
        })
        if(this.response_data.votifier_success != "none"){
        this.tableData.push({
          key: "Votifier 결과",
          value: (this.response_data.votifier_success == "false" ? "실패" : "성공")
        })
        }
        this.tableData.push({
          key: "요청 시각",
          value: this.response_data.time
        })
      }
      else if(this.response_data.status == 0){
        this.tableData.push({
          key: "상태",
          value: "대기 중"
        })
        this.tableData.push({
          key: "요청 시각",
          value: this.response_data.time
        })
        this.tableData.push({
          key: "대기 순번",
          value: this.response_data.inqueue
        })
      }
      else if(this.response_data.status == 2){
        this.tableData.push({
          key: "상태",
          value: this.response_data.message ? "거부됨 (" + this.response_data.message + ")" : "거부됨"
        })
        this.tableData.push({
          key: "요청 시각",
          value: this.response_data.time
        })
      } 
    },
    loadVoteStatus(){
      loadingInstance = Loading.service({ fullscreen: true });
      this.response_load = false;
      this.response_button_text = "조회 중";

      var vm = this;
        axios.post(apiUrl+'/server/voteStatus', {messageId: this.messageId}, { withCredentials: true })
        .then(function(response) {
          if(response.data.success){
            vm.response_data = response.data;
            vm.makeTable();
            vm.response_load = true;
            vm.response_button_text = "조회";
            loadingInstance.close();
          }
          else{
            loadingInstance.close();
            vm.response_load = true;
            vm.response_button_text = "조회";
            vm.$swal.fire(
                '일치하는 요청 ID가 없습니다.',
                "일정 기간이 초과된 추천 요청은 조회되지 않을 수 있습니다.",
                'error'
              )
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
	},
	mounted(){
    if(this.$route.query.messageId){
      this.messageId = this.$route.query.messageId;
      this.loadVoteStatus(); 
    }
	},
  beforeCreate(){



  }
}
</script>

<style scoped>
.box-card {
  margin-bottom: 15px;
}


</style>
